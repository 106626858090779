import { useEffect, useState } from "react";
import useAuth from "../../context/AuthProvider";
import { TaxTransactionReportModel, TaxTransactionReportModel_Resp } from "../../models/TaxTransactionReportModel";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectProps, TextField } from "@mui/material";
import { FieldAttributes, Form, Formik, useField } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { config } from "../../Constants";
import Swal from "sweetalert2";
import { FMTextField } from "../Elements/input";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';


interface Iprops {
    StateRuleMatrixCL: TaxTransactionReportModel_Resp,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: TaxTransactionReportModel_Resp
     fetchDataMain: (
        pageSize: number,
        currentPage: number,
        tabValue: number
    ) => void;
    tabValue: number
    completedPage: boolean
}

export const AddEditDiligentSearh: React.FC<Iprops> = ({ handleClose, Open, StateRuleMatrixCL, handleSubmit, initValues,fetchDataMain ,tabValue, completedPage}) => {
    const initialValues: TaxTransactionReportModel_Resp = initValues ? StateRuleMatrixCL : initValues;
    const { user } = useAuth();
    const token = user?.token;
    const [dateCompleted, setDateCompleted] = useState<string>("");

    const shouldDisableDate = (date: Date) => {
        const minDate = new Date('1900-01-01')
        const maxDate = new Date()
        return date < minDate || date > maxDate;
    };

    const handleCloseBox = () => {
        initialValues.needDiligentSearch = '';
        initialValues.diligentSearchCompleted = '';
        setDateCompleted(StateRuleMatrixCL.dateCompleted )
        handleClose()
    }
    const handleDateCompleted = (event: any) => {
        console.log("date completed",dateCompleted)
        if (event) {
            let formattedDate = '';
            try {
                const date = new Date(event);
                const month = ('0' + (date.getMonth() + 1)).slice(-2);  // Add leading zero if needed
                const day = ('0' + date.getDate()).slice(-2);           // Add leading zero if needed
                const year = date.getFullYear();
                
                // Construct the formatted date as MM/dd/yyyy
                formattedDate = `${month}/${day}/${year}`;
            } catch {
                formattedDate = event.toLocaleString();
            }
            setDateCompleted(formattedDate);
        }
    };
// debugger
    const FMSelectCarrierCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { insuranceCompanyId: 0, name: "" };
        const [Carrier, setCarrier] = useState([initialdata])

        const fetchData = () => {
            fetch(config.url.API_URL + '/Carrier/getCarrierSelect',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response
                    if (responseJson.data != null) {
                        // console.log(responseJson.data)
                        setCarrier(responseJson.data)
                    }
                }).catch((error) => {
                    console.log(error)
                });
        }

        useEffect(() => {
            fetchData();
        }, [])


        return (
            <React.Fragment>

                <FormControl error={!!errorText} fullWidth sx={{ m: 1 }} >
                    <InputLabel id="State-Select-Label">Carrier</InputLabel>
                    <Select {...field}
                        labelId="State-Select-Label"
                        label="Carrier"
                        value={field.value || ""}
                        className="border-round main-form-2"
                        IconComponent={ExpandMoreIcon}
                    >
                        {/* <MenuItem value={"0"} >Carrier</MenuItem> */}
                        {Carrier.map((carrier, key) =>
                            <MenuItem key={key} value={carrier.insuranceCompanyId}> {carrier.name} </MenuItem>
                        )}
                    </Select>
                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
            </React.Fragment>
        )
    }
    const FMSelectTransactionType: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialData = { transactionId: 0, transactionTypeName: "" };
        const [transactionTypes, setTransactionTypes] = useState([initialData]);
        const [dateCompleted, setDateCompleted] = useState<string>('');
    
        const fetchTransactionData = () => {
            fetch(`${config.url.API_URL}/TransactionType/getAllTransactionType`, {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    if (responseJson.data) {
                        setTransactionTypes(responseJson.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };
    
        useEffect(() => {
            fetchTransactionData();
        }, []);
    
        return (
            <React.Fragment>
                <FormControl error={!!errorText} fullWidth>
                    <InputLabel id="transaction-type-select-label">Transaction Type</InputLabel>
                    <Select
                        {...field}
                        labelId="transaction-type-select-label"
                        label="Transaction Type"
                        value={field.value || ""}
                        className="border-round main-form-2"
                        IconComponent={ExpandMoreIcon}
                    >
                        {transactionTypes.map((transactionType) => (
                            <MenuItem key={transactionType.transactionId} value={transactionType.transactionId}>
                                {transactionType.transactionTypeName}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
            </React.Fragment>
        );
    };
    useEffect(() => {
        // fetchStateData();
        console.log("initialValues",JSON.stringify(initialValues))
        setDateCompleted(initialValues.dateCompleted )
    }, [initialValues])
    return (

        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle className='card-title' >Diligent Search</DialogTitle>
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                onSubmit={(data, { setSubmitting }) => {

                    console.log("finaldata", data);
                    fetch(
                        config.url.API_URL + '/DiligentSearchReport/AddEditDiligentSearch',
                        {
                            method: 'POST',

                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify({ 
                                ...data, 
                                dateCompleted: new Date(dateCompleted).toISOString() 
                              })

                        })
                        .then((response) => {
                            if (response.ok) {

                                return response.json();

                            }
                            throw new Error('Something went wrong');
                        })
                        .then((res) => {
                            // Do something with the response

                            handleSubmit()
                            setSubmitting(true)
                            Swal.fire({
                                title: "Data Successfully saved",
                                text: "",
                                icon: "success",
                                confirmButtonText: "OK",
                            });
                            const action =
                            tabValue === 1
                                ? "getOutstandingFilingsReportData"
                                : tabValue === 2
                                    ? "getCompletedFilingsReportData"
                                    : tabValue === 3 ? "getClosedFilingsReportData" : "";
                            fetchDataMain(50, 1, tabValue);
                        }).catch((error) => {
                            console.log(error)
                        });
                }} >
                {({ values, isSubmitting, setFieldValue, errors }) => (
                    <Form className='main-form' >
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="insuredName" autoFocus margin="dense" disabled label="Insured Name" placeholder="Insured Name" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="client" autoFocus margin="dense" disabled label="Client" placeholder="client" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="diligentSearchNotes" autoFocus margin="dense"  label="Diligent Search Notes" placeholder="diligentSearchNotes" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="policyNumber" disabled autoFocus margin="dense" label="Policy Number" placeholder="policyNumber" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="need-diligent-search-label">Need Diligent Search</InputLabel>
                                        <Select
                                            labelId="need-diligent-search-label"
                                            name="needDiligentSearch"
                                            value={initialValues.needDiligentSearch?.toUpperCase()}
                                            onChange={(event) => {
                                                setFieldValue("needDiligentSearch", event.target.value)
                                                initialValues.needDiligentSearch = event.target.value
                                            }}
                                            margin="dense"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ m: 1 }}
                                        >
                                            <MenuItem value="TRUE">TRUE</MenuItem>
                                            <MenuItem value="FALSE">FALSE</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="diligent-search-completed-label">Diligent Search Completed</InputLabel>
                                        <Select
                                            labelId="diligent-search-completed-label"
                                            name="diligentSearchCompleted"
                                            value={initialValues.diligentSearchCompleted?.toUpperCase()}
                                            onChange={(event) => {
                                                setFieldValue("diligentSearchCompleted", event.target.value)
                                                initialValues.diligentSearchCompleted = event.target.value
                                            }}
                                            margin="dense"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ m: 1 }}
                                        >
                                            <MenuItem value="TRUE">TRUE</MenuItem>
                                            <MenuItem value="FALSE">FALSE</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <DatePicker
                                                label="Date Completed"
                                                className="select-input-border text-input-box"
                                                value={dateCompleted}
                                                onChange={handleDateCompleted}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        {...params} />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </LocalizationProvider>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="suspended" autoFocus margin="dense" disabled label="Suspended" placeholder="Suspended" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="transactionType" autoFocus margin="dense" disabled label="Transaction Type" placeholder="Transaction Type" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                    
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="carrier" autoFocus margin="dense" disabled label="carrier" placeholder="carrier" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="naic" autoFocus margin="dense" disabled label="NAIC" placeholder="NAIC" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="policyEffDate" autoFocus margin="dense" disabled label="Policy Effective Date" placeholder="policyEffDate" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="policyExpDate" autoFocus margin="dense" disabled label="Policy Expiration Date" placeholder="policyExpDate" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="grossPremium" autoFocus margin="dense" disabled label="Gross Premium" placeholder="grossPremium" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn" type='button' onClick={handleCloseBox}  >Cancel</button>
                            <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog >
    )
}
